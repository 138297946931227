export const BUYER_INITIALS = {
    key: 'buyer_initials',
    label: 'Buyer initials'
};

export const BUYER_SIGNATURE = {
    key: 'buyer_signature',
    label: 'Buyer signature'
};

export const BUYER_SIGNATURE_DATE = {
    key: 'buyer_signature_date',
    label: 'Buyer signature date'
};

export const SELLER_INITIALS = {
    key: 'seller_initials',
    label: 'Seller initials'
};

export const SELLER_SIGNATURE = {
    key: 'seller_signature',
    label: 'Seller signature'
};

export const SELLER_SIGNATURE_DATE = {
    key: 'seller_signature_date',
    label: 'Seller signature date'
};

export const REQUIRED_TEXT_FIELD = {
    key: 'req_text_field',
    label: 'Required text'
};

export const OPTIONAL_TEXT_FIELD = {
    key: 'opt_text_field',
    label: 'Optional text'
};

export const FILL_AND_SIGN_FIELDS = [
    BUYER_INITIALS,
    BUYER_SIGNATURE,
    SELLER_INITIALS,
    SELLER_SIGNATURE,
    REQUIRED_TEXT_FIELD,
    OPTIONAL_TEXT_FIELD
]
