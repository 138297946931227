export default {
    methods: {
        async replaceContent(webviewer, search, replace) {
            const {documentViewer, PDFNet} = webviewer.Core;

            await PDFNet.initialize();
            const document = await documentViewer.getDocument().getPDFDoc();

            await PDFNet.runWithCleanup(async () => {
                await document.lock();

                const replacer = await PDFNet.ContentReplacer.create();
                const pageCount = await document.getPageCount();
                
                for (let page = 1; page <= pageCount; page++) {
                    const currentPage = await document.getPage(page);

                    await replacer.addString(search, replace);

                    await replacer.process(currentPage);
                }
            });

            documentViewer.refreshAll();
            documentViewer.updateView();
            documentViewer.getDocument().refreshTextData();
        }
    }
}
