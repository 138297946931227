<template>
    <CModal scrollable size="xl" class="py-4" :visible="visible" @close="$emit('closed')">
        <CModalHeader>
            <CModalTitle>Document Preview</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body">
            <div v-if="isThirdParty" class="webviewer-container">
                <div class="webviewer" ref="viewer"></div>
            </div>
            <iframe v-else :src="source"></iframe>
        </CModalBody>
    </CModal>
</template>

<script>
import {baseURL, getVerUri} from '@/services/ApiService';
import BaseConfigs from "@/api/v2/BaseConfigs";
import WebViewer from "@pdftron/webviewer";
import config from "@/domain/config";
import {PDF} from "@/domain/Entities/Upload/extensions";
import webviewerHelper from "@/mixin/webviewerHelper";
import {BUYER_SIGNATURE_DATE, SELLER_SIGNATURE_DATE} from "@/domain/Entities/Shortcode/shortcodes";

const DOCUMENT_TYPES = ['scopes', 'tasks', 'templates', 'standard-templates'];
const DEFAULT_PREVIEWER = 'default';
const THIRD_PARTY_PREVIEWER = 'third-party';

export default {
    mixins: [webviewerHelper],
    props: {
        previewer: {
            type: String,
            default: DEFAULT_PREVIEWER,
            validator: (value) => [DEFAULT_PREVIEWER, THIRD_PARTY_PREVIEWER].includes(value)
        },
        visible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
            validator: (value) => DOCUMENT_TYPES.includes(value),
        },
        typeId: {
            type: Number,
            required: true,
        },
        route: {
            type: String,
            default: 'preview',
        },
        query: {
            type: Object,
            default: null
        },
        documentName: {
            type: String,
            default: ''
        },
        extension: {
            type: String,
            default: PDF
        }
    },
    computed: {
        isThirdParty() {
            return this.previewer === THIRD_PARTY_PREVIEWER;
        },
        source() {
            const random = Math.random();

            return baseURL
                + getVerUri(`${this.type}/${this.typeId}/${this.route}/${this.documentName}`, 2)
                + `?v=${random}`
                + this.urlQuery;
        },
        urlQuery() {
            return this.query ? this.queryToString() : '';
        }
    },
    watch: {
        visible(value) {
            if (value && this.isThirdParty) {
                this.setUpWebviewerPreviewer();
            }
        }
    },
    methods: {
        queryToString() {
            let query = '';

            Object.keys(this.query).forEach(key => query += `&${key}=${this.query[key]}`)

            return query
        },
        setUpWebviewerPreviewer() {
            this
                .loadWordDocument()
                .then(blob => this.setUpWebviewer(blob));
        },
        loadWordDocument() {
            return new BaseConfigs()
                .asDownload(this.source)
                .then(response => response.data)
        },
        getWebviewerInstance(blob) {
            return WebViewer.Iframe({
                initialDoc: URL.createObjectURL(blob),
                licenseKey: config.services.apryse.licenseKey,
                path: `${config.app.baseUrl}/webviewer`,
                filename: this.documentName,
                extension: this.extension,
                isReadOnly: true,
                fullAPI: true,
            }, this.$refs.viewer);
        },
        setUpWebviewer(blob) {
            this.getWebviewerInstance(blob)
                .then(instance => {
                    this.setUpWebviewerUI(instance);

                    instance.Core.documentViewer
                        .addEventListener('annotationsLoaded', () => {
                            this.disableWebviewerDocFields(instance);
                            this.replaceContent(instance, BUYER_SIGNATURE_DATE.key, '');
                            this.replaceContent(instance, SELLER_SIGNATURE_DATE.key, '');
                        });
                });
        },
        setUpWebviewerUI(instance) {
            instance.UI.setZoomLevel('100%');
            instance.UI.disableElements([
                'annotationCommentButton',
                'contextMenuPopup',
                'notesPanelToggle',
                'linkButton',
            ]);
        },
        disableWebviewerDocFields(instance) {
            const {annotationManager, Annotations} = instance.Core;

            annotationManager
                .getAnnotationsList()
                .filter(annotation => annotation instanceof Annotations.WidgetAnnotation)
                .forEach(widgetAnnotation => widgetAnnotation.fieldFlags.set(instance.Core.Annotations.WidgetFlags.READ_ONLY));
        }
    }
};
</script>

<style scoped lang="scss">
.content-body {
    height: 100vh;

    .webviewer-container {
        display: flex;
        height: 98%;

        .webviewer {
            flex: 1;
        }
    }

    iframe {
        height: 95%;
        width: 100%;
    }
}
</style>
